<script setup lang="ts">
const { t } = useI18n();

const props = defineProps({
  duration: {
    type: Number,
    required: true,
  },
});

const text = computed(() => {
  const hours = Math.floor(props.duration / 3600);
  const minutes = Math.ceil((props.duration - (hours * 3600)) / 60);

  return hours > 0
      ? (
          minutes > 0
              ? t("formatted.queue.duration.time_hm", {h: hours, m: minutes})
              : t("formatted.queue.duration.time_h", {m: minutes})
      )
      : t("formatted.queue.duration.time_m", {m: minutes});
});
</script>

<template>
  <span>{{ text }}</span>
</template>
